<template>
  <div class="addPageVehicle">
    <div class="facilityBox">
      <el-breadcrumb separator="/" style="margin:0 0 10px 0;">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>客户档案</el-breadcrumb-item>
        <el-breadcrumb-item>车辆信息</el-breadcrumb-item>
      </el-breadcrumb>
      <el-form class="vehicleInfo" :rules="rules" :model="vehicleInfo" ref="vehicleInfo" label-width="140px" inline>
        <!-- 证照信息 -->
        <div class="title">证照信息</div>
        <div class="content">
          <div class="upBox">
            <el-image class="img" :src="vehicleImg.vehicleFace.picUrl" :preview-src-list="srcList">
            </el-image>
            <div class="upText">车辆行驶证正页</div>
          </div>
          <div class="upBox">
            <el-image class="img" :src="vehicleImg.vehiclePageOn.picUrl" :preview-src-list="srcList">
            </el-image>
            <div class="upText">车辆行驶证副页(正面)</div>
          </div>
          <div class="upBox" v-if="vehicleImg.vehicleBack.picUrl">
            <el-image class="img" :src="vehicleImg.vehicleBack.picUrl" :preview-src-list="srcList">
            </el-image>
            <div class="upText">车辆行驶证副页(反面)</div>
          </div>
          <div class="upBox" v-if="vehicleImg.vehicleRTP.picUrl">
            <el-image class="img" :src="vehicleImg.vehicleRTP.picUrl" :preview-src-list="srcList">
            </el-image>
            <div class="upText">道路运输经营许可证</div>
          </div>
          <div class="upBox" v-if="vehicleImg.elsePapers.picUrl">
            <el-image class="img" :src="vehicleImg.elsePapers.picUrl" :preview-src-list="srcList">
            </el-image>
            <div class="upText">附加证照</div>
          </div>
        </div>
        <!-- 基本信息 -->
        <div class="title">基本信息</div>
        <el-form-item label="车牌号" prop="VehicleCarNumber">
          <el-input v-model="vehicleInfo.VehicleCarNumber" placeholder="请输入车牌号"
            :disabled="VehicleCarNumber ? true : false"></el-input>
        </el-form-item>
        <el-form-item label="车牌颜色" prop="CarNumberColor" required>
          <el-select v-model="vehicleInfo.CarNumberColor" clearable placeholder="请选择车牌颜色">
            <el-option v-for="item in vehicleColorList" :key="item.Name" :label="item.Name" :value="item.Code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆类型" prop="VehicleTypeName" required>
          <el-input v-model="vehicleInfo.VehicleTypeName" placeholder="请输入车辆类型"></el-input>
        </el-form-item>
        <el-form-item label="所有人" prop="VehicleMaster" required>
          <el-input v-model="vehicleInfo.VehicleMaster" placeholder="请输入所有人"></el-input>
        </el-form-item>
        <el-form-item label="载重(吨)" prop="VehicleLoad" required>
          <el-input v-model="vehicleInfo.VehicleLoad"
            onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
            placeholder="请输入载重(吨)"></el-input>
        </el-form-item>
        <el-form-item label="自重(吨)" prop="VehicleTotalWeight" required>
          <el-input v-model="vehicleInfo.VehicleTotalWeight"
            onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
            placeholder="请输入自重(吨)"></el-input>
        </el-form-item>
        <el-form-item label="总重(吨)" prop="VehicleLoadWeight">
          <el-input v-model="vehicleInfo.VehicleLoadWeight"
            onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
            placeholder="请输入总质量"></el-input>
        </el-form-item>
        <el-form-item label="行驶证有效期" prop="VehicleLicenseValidDate">
          <el-date-picker v-model="vehicleInfo.VehicleLicenseValidDate" type="date" value-format="yyyy-MM-dd"
            placeholder="请选择行驶证有效期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="车长(米)" prop="VehicleLength" required>
          <el-input v-model="vehicleInfo.VehicleLength"
            onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
            placeholder="请输入车身长度"></el-input>
        </el-form-item>
        <el-form-item label="车宽(米)" prop="VehicleWidth">
          <el-input v-model="vehicleInfo.VehicleWidth"
            onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
            placeholder="请输入车身宽度"></el-input>
        </el-form-item>
        <el-form-item label="车高(米)" prop="VehicleHeight">
          <el-input v-model="vehicleInfo.VehicleHeight"
            onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
            placeholder="请输入车高(米)"></el-input>
        </el-form-item>
        <el-form-item label="能源类型" prop="VehiclePowerType" required>
          <el-select v-model="vehicleInfo.VehiclePowerType" clearable placeholder="能源类型">
            <el-option v-for="item in powerList" :key="item.Name" :label="item.Name" :value="item.Code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用性质" prop="VehicleUsage">
          <el-input v-model="vehicleInfo.VehicleUsage" placeholder="请输入使用性质"></el-input>
        </el-form-item>
        <el-form-item label="品牌型号" prop="BrandModelNumber">
          <el-input v-model="vehicleInfo.BrandModelNumber" placeholder="请输入品牌型号"></el-input>
        </el-form-item>
        <el-form-item label="发动机号" prop="PowerNumber">
          <el-input v-model="vehicleInfo.PowerNumber" placeholder="请输入发动机号"></el-input>
        </el-form-item>
        <el-form-item label="车辆识别代码" prop="VehicleVIN">
          <el-input v-model="vehicleInfo.VehicleVIN" placeholder="请输入车辆识别代码"></el-input>
        </el-form-item>
        <el-form-item label="行驶证注册日期" prop="VehicleLicenseRegDate">
          <el-date-picker v-model="vehicleInfo.VehicleLicenseRegDate" type="date" value-format="yyyy-MM-dd"
            placeholder="请选择行驶证注册日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="行驶证发证日期" prop="VehicleLicenseAwardDate">
          <el-date-picker v-model="vehicleInfo.VehicleLicenseAwardDate" type="date" value-format="yyyy-MM-dd"
            placeholder="请选择行驶证发证日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="道路运输证号" prop="vehicleRTP">
          <el-input v-model="vehicleInfo.VehicleRTP" placeholder="请输入道路运输证号"></el-input>
        </el-form-item>
        <el-form-item label="核载人数" prop="VehicleLoadPersons">
          <el-input onkeyup="value=value.replace(/[^\d]/g,'')" v-model="vehicleInfo.VehicleLoadPersons"
            placeholder="请输入核载人数"></el-input>
        </el-form-item>
        <el-form-item label="购车日期" prop="VehicleBuyDate">
          <el-date-picker v-model="vehicleInfo.VehicleBuyDate" type="date" value-format="yyyy-MM-dd"
            placeholder="请选择购车日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="年审日期" prop="InspectAnnuallyDate">
          <el-date-picker v-model="vehicleInfo.InspectAnnuallyDate" type="date" value-format="yyyy-MM-dd"
            placeholder="请选择年审日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="商业险到期日期" prop="BInsuranceDate">
          <el-date-picker v-model="vehicleInfo.BInsuranceDate" type="date" value-format="yyyy-MM-dd"
            placeholder="请选择商业险到期日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="交强险到期日期" prop="CInsuranceDate">
          <el-date-picker v-model="vehicleInfo.CInsuranceDate" type="date" value-format="yyyy-MM-dd"
            placeholder="请选择交强险到期日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="轴数" prop="VehicleAxlesNumber">
          <el-input v-model="vehicleInfo.VehicleAxlesNumber" placeholder="请输入轴数" maxlength="4"
            oninput="value=value.replace(/[^\d]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="发证机关" prop="VehicleLicesenAuthority" required>
          <el-input v-model="vehicleInfo.VehicleLicesenAuthority" placeholder="请输入发证机关"></el-input>
        </el-form-item>
        <el-form-item label="归属类型" prop="VehicleAttribution">
          <el-select v-model="vehicleInfo.VehicleAttribution" clearable placeholder="归属类型">
            <el-option v-for="item in affiliation" :key="item.Name" :label="item.Name" :value="item.Code">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="head">
        <el-button size="medium" icon="el-icon-arrow-left" @click="routerBack()">返回</el-button>
        <el-button size="medium" type="primary" icon="el-icon-edit" @click="saveEdit()">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getDataDict } from '@/api/common/common'
import { GetTCVehicleInfo, UpdateVehiciInfo } from '@/api/transport/vehicleManage/index'
export default {
  data() {
    return {
      // 归属类型
      affiliation: [],
      // 图片放大预览
      srcList: [],
      rules: {
        CarNumberColorName: [
          { required: true, message: '请选择车牌颜色', trigger: 'change' }
        ],
        VehicleTypeName: [
          { required: true, message: '请选择车辆类型', trigger: 'change' }
        ],
        VehicleMaster: [
          { required: true, message: '请输入所有人', trigger: 'blur' }
        ],
        VehicleLoad: [
          { required: true, message: '请输入载重(吨)', trigger: 'blur' }
        ],
        VehicleLoadWeight: [
          { required: true, message: '请输入总重(吨)', trigger: 'blur' }
        ],
        VehicleLength: [
          { required: true, message: '请输入车长(米)', trigger: 'blur' }
        ],
        VehiclePowerType: [
          { required: true, message: '请选择能源类型', trigger: 'change' }
        ],
        VehicleLicesenAuthority: [
          { required: true, message: '请输入发证机关', trigger: 'blur' }
        ]
      },
      pics: {},
      // 基本信息
      vehicleInfo: {
        VehicleCarNumber: '',
        CarNumberColorName: '',
        VehicleType: '',
        VehicleLoad: '',
        VehicleLength: '',
        VehicleWidth: '',
        vehicleLicenseValidDate: '',
        vehicleRTP: '',
        VehicleLoadWeight: '',
        VehicleMaster: '',
        VehiclePowerType: '',
        VehicleTotalWeight: '',
        VehicleHeight: '',
        VehicleUsage: '',
        BrandModelNumber: '',
        PowerNumber: '',
        VehicleVIN: '',
        VehicleLicenseRegDate: '',
        VehicleLicenseAwardDate: '',
        VehicleLicesenAuthority: '',
        VehicleLoadPersons: '',
        VehicleBuyDate: '',
        InspectAnnuallyDate: '',
        BInsuranceDate: '',
        CInsuranceDate: '',
        VehicleAxlesNumber: ''
      },
      // 证件照片
      vehicleImg: {
        //车辆行驶证(正页)
        vehicleFace: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        //车辆行驶证副页(正面)
        vehicleBack: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        //车辆行驶证副页(反面)
        vehiclePageOn: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        //道路运输经营许可证
        vehicleRTP: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        //其他证件
        elsePapers: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        }
      },
      //车辆颜色
      vehicleColorList: [],
      //能源类型
      powerList: [],
      // tab状态
      urlType: '',
      VehicleCarNumber: '',
      pageIndex: 1
    }
  },
  methods: {
    // 保存编辑
    saveEdit() {
      const loading = this.$loading({
        lock: true,
        text: '保存中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let params = {
        baseInfo: {
          ...this.vehicleInfo
        },
        confirmInfo: {
          ...this.pics
        }
      }
      UpdateVehiciInfo(params).then(() => {
        this.$message.success("保存成功")
        this.getVehicleDeatils(this.VehicleCarNumber)
        loading.close()
      }).catch(() => {
        loading.close()
      })
    },
    // 返回上一页
    routerBack() {
      this.$router.push({
        path: "/platform/alluser/index",
        query: {
          urlType: this.urlType,
          pageIndex: this.pageIndex
        },
      });
    },
    // 获取车辆信息
    getVehicleDeatils(CarNumber) {
      GetTCVehicleInfo({ CarNumber }).then(res => {
        this.vehicleInfo = res.baseInfo
        this.pics = res.confirm
        this.vehicleImg.vehicleFace.picUrl = res.confirm.VehicleLicenseFrontPageURL
        this.vehicleImg.vehicleBack.picUrl = res.confirm.VehicleLicenseSubPageBackURL
        this.vehicleImg.vehiclePageOn.picUrl = res.confirm.VehicleLicenseSubPageOnURL
        this.vehicleImg.vehicleRTP.picUrl = res.confirm.VehicleRTPUTL
        this.vehicleImg.elsePapers.picUrl = res.confirm.AdditionalLicenseURL
        if (this.vehicleImg.vehicleFace.picUrl) {
          this.srcList.push(this.vehicleImg.vehicleFace.picUrl);
        }
        if (this.vehicleImg.vehiclePageOn.picUrl) {
          this.srcList.push(this.vehicleImg.vehiclePageOn.picUrl);
        }
        if (this.vehicleImg.vehicleBack.picUrl) {
          this.srcList.push(this.vehicleImg.vehicleBack.picUrl);
        }
        if (this.vehicleImg.vehicleRTP.picUrl) {
          this.srcList.push(this.vehicleImg.vehicleRTP.picUrl);
        }
        if (this.vehicleImg.elsePapers.picUrl) {
          this.srcList.push(this.vehicleImg.elsePapers.picUrl);
        }
      })
    }
  },
  created() {
    // 编辑类型
    this.VehicleCarNumber = this.$route.query.VehicleCarNumber
    this.urlType = this.$route.query.urlType
    this.pageIndex = this.$route.query.pageIndex ? this.$route.query.pageIndex : 1
    // 获取车辆信息
    this.getVehicleDeatils(this.VehicleCarNumber)
    // 车牌颜色
    getDataDict({ type: 3 }).then(res => {
      this.vehicleColorList = res.patterSetInfo
    })
    // 能源类型
    getDataDict({ type: 10 }).then(res => {
      this.powerList = res.patterSetInfo
    })
    // 车辆归属类型
    getDataDict({ type: 15 }).then(res => {
      this.affiliation = res.patterSetInfo
    })
  }
}
</script>

<style lang="scss">
.addPageVehicle {
  .el-input__inner {
    width: 220px;
  }

  .el-form-item__content {
    width: 220px;
  }

  .el-input.is-disabled .el-input__inner {
    color: #000;
  }
}
</style>

<style lang="scss" scoped>
@import '../../../../assets/style/variable.scss';

.addPageVehicle {
  .head {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .upBox {
      text-align: center;

      .upText {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
        color: #333333;
      }
    }

    .el-upload__tip {
      color: $textGray2;
    }
  }

  .title {
    color: $primary;
    position: relative;
    text-indent: 15px;
    font-size: 14px;
    border-bottom: 1px solid $borderGray;
    padding: 20px 0;
    margin-bottom: 20px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      border-radius: 5px;
      background: $primary;
    }
  }
}

.img {
  width: 278px;
  height: 174px;
  border-radius: 10px;
}
</style>